exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-admission-list-admission-list-json-link-js": () => import("./../../../src/pages/admission-list/{admissionListJson.link}.js" /* webpackChunkName: "component---src-pages-admission-list-admission-list-json-link-js" */),
  "component---src-pages-agendas-agendas-json-date-js": () => import("./../../../src/pages/agendas/{agendasJson.date}.js" /* webpackChunkName: "component---src-pages-agendas-agendas-json-date-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-index-js": () => import("./../../../src/pages/news/index.js" /* webpackChunkName: "component---src-pages-news-index-js" */),
  "component---src-pages-news-mdx-slug-js": () => import("./../../../src/pages/news/{mdx.slug}.js" /* webpackChunkName: "component---src-pages-news-mdx-slug-js" */),
  "component---src-pages-partner-js": () => import("./../../../src/pages/partner.js" /* webpackChunkName: "component---src-pages-partner-js" */),
  "component---src-pages-scene-js": () => import("./../../../src/pages/scene.js" /* webpackChunkName: "component---src-pages-scene-js" */),
  "component---src-pages-sign-up-js": () => import("./../../../src/pages/sign-up.js" /* webpackChunkName: "component---src-pages-sign-up-js" */),
  "component---src-pages-slides-js": () => import("./../../../src/pages/slides.js" /* webpackChunkName: "component---src-pages-slides-js" */),
  "component---src-pages-teacher-js": () => import("./../../../src/pages/teacher.js" /* webpackChunkName: "component---src-pages-teacher-js" */),
  "component---src-pages-traffic-js": () => import("./../../../src/pages/traffic.js" /* webpackChunkName: "component---src-pages-traffic-js" */),
  "component---src-pages-video-js": () => import("./../../../src/pages/video.js" /* webpackChunkName: "component---src-pages-video-js" */)
}

